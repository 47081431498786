import React, { useState } from 'react';

import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
} from '@mui/material';

import globalConfig from "../../utils/config";

import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import { FormInputCheckbox } from '../shared/FormInputCheckbox';

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          setAddressInfo={props.setAddressInfo}
          addressInfo={props.addressInfo}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item
      md={6}
      xs={10}
      lg={4}
    >
      <FormInputDropdown name={name}
        control={control}
        label={label}
        options={options}
        rules={rules}
        readonly={readonly || false}
      />
      {/* <FormInputRadio
          name={name}
          control={control}
          label={label}
          options={options}
          rules={rules}
        /> */}
    </Grid>
  );
}

const AddressDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
  const indianStates = globalConfig.states;
  let states = Object.entries(indianStates).map((val, key) => { return { label: val[1], value: val[0] } });

  let defaultValues = {
    permenant_number: customerInfo.permenant_number || '',
    permenant_address1: customerInfo.permenant_address1 || '',
    permenant_address2: customerInfo.permenant_address2 || '',
    permenant_city_village: customerInfo.permenant_city_village || '',
    permenant_district: customerInfo.permenant_district || '',
    permenant_state: customerInfo.permenant_state || '',
    permenant_landmark: customerInfo.permenant_landmark || '',
    permenant_pin_code: customerInfo.permenant_pin_code || '',
    permenant_no_years:  customerInfo.permenant_no_years || '',
    permenant_no_months:  customerInfo.permenant_no_months || '',

    address_same: customerInfo.address_same || false,
    present_number: customerInfo.present_number || '',
    present_address1: customerInfo.present_address1 || '',
    present_address2: customerInfo.present_address2 || '',
    present_city_village: customerInfo.present_city_village || '',
    present_district: customerInfo.present_district || '',
    present_state: customerInfo.present_state || '',
    present_landmark: customerInfo.present_landmark || '',
    present_pin_code: customerInfo.present_pin_code || '',
    residence_type: customerInfo.residence_type || '',
    present_no_years:  customerInfo.present_no_years || '',
    present_no_months:  customerInfo.present_no_months || '',
  };

  const [addressInfo] = useState(defaultValues);

  const ResidentOptions = [
    {
      label: 'Owned',
      value: 'owned'
    },
    {
      label: 'Rent',
      value: 'rent'
    },
    {
      label: 'Lease',
      value: 'lease'
    }
  ]

  const years = Array.from({ length: 50 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const months = Array.from({ length: 11 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: { ...addressInfo }
  });

  // const permenant = [
  //   'permenant_number',
  //   'permenant_address1',
  //   'permenant_address2',
  //   'permenant_city_village',
  //   'permenant_district',
  //   'permenant_state'
  // ]

  const context = watch();
  // const addressCheck = watch([...permenant]);

  const onSubmit = (data) => {
    console.log(data);
    setCustomerInfo({
      ...customerInfo, ...data
    })
    handleNext();
  }

  const onChangeHandler = (isChecked) => {
    let data = {
      ...context,
      address_same: isChecked,
      present_number: context.permenant_number,
      present_address1: context.permenant_address1,
      present_address2: context.permenant_address2,
      present_city_village: context.permenant_city_village,
      present_district: context.permenant_district,
      present_state: context.permenant_state,
      present_landmark: context.permenant_landmark,
      present_pin_code: context.permenant_pin_code,
      present_no_years: context.permenant_no_years,
      present_no_months: context.permenant_no_months,
    }
    isChecked && reset(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Card>
        <CardHeader
          title="Address Details"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item spacing={2}>
              <FormRowRadio
                name="residence_type"
                control={control}
                label="Residence Type"
                options={ResidentOptions} rules={CustomerRules.residence_type} readonly={isNextActive}
              />
            </Grid>
            <Grid container item spacing={2}>
              <FormRow control={control} name="permenant_number" label="House Number" readonly={isNextActive} />
              <FormRow control={control} name="permenant_address1" label="Address1" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2}>
              <FormRow control={control} name="permenant_address2" label="Address2" readonly={isNextActive} />
              <FormRow control={control} name="permenant_city_village" label="City/Town/Village" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2}>
              <FormRow control={control} name="permenant_district" label="District" readonly={isNextActive} />
              <FormRow control={control} name="permenant_landmark" label="Landmark" readonly={isNextActive} />

            </Grid>
            <Grid container item spacing={2}>
              <Grid item md={6} xs={10} lg={4} >
                <FormInputDropdown name="permenant_state" control={control} label="State" options={states} readonly={isNextActive} />
              </Grid>
              <FormRow control={control} name="permenant_pin_code" label="Pincode" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} >
              <Grid item md={6} xs={10} lg={4}  >
                <FormInputDropdown name="permenant_no_years" control={control} label="No of years" options={years} readonly={isNextActive} />
              </Grid>
              <Grid item md={6} xs={10} lg={4} >
              <FormInputDropdown name="permenant_no_months" control={control} label="No of months" options={months} readonly={isNextActive} />
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item md={12} xs={12} lg={12} >
                <FormInputCheckbox control={control} name="address_same" label="check if same as permenant address" readOnly={isNextActive} onChangeHandler={onChangeHandler}
                //    readOnly={addressCheck.filter(Boolean).length==6 ? false : true}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <FormRow control={control} name="present_number" label="House Number" readonly={isNextActive} />
              <FormRow control={control} name="present_address1" label="Address1" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2}>
              <FormRow control={control} name="present_address2" label="Address2" readonly={isNextActive} />
              <FormRow control={control} name="present_city_village" label="City/Town/Village" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2}>
              <FormRow control={control} name="present_district" label="District" readonly={isNextActive} />
              <FormRow control={control} name="present_landmark" label="Landmark" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item md={6} xs={10} lg={4} >
                <FormInputDropdown name="present_state" control={control} label="State" options={states} readonly={isNextActive} />
              </Grid>
              <FormRow control={control} name="present_pin_code" label="Pincode" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} >
              <Grid item md={6} xs={10} lg={4}  >
                <FormInputDropdown name="present_no_years" control={control} label="No of years" options={years} readonly={isNextActive} />
              </Grid>
              <Grid item md={6} xs={10} lg={4} >
              <FormInputDropdown name="present_no_months" control={control} label="No of months" options={months} readonly={isNextActive} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: '36px' }}
          >
            Cancel
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                Previous
              </Button>
            )}

            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
            </Button>
          </Box>
        </Box>
      </Card>
    </form>
  );
};


export default AddressDetails;
