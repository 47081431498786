import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { PastInputDate } from '../shared/FormInputDate';
import { services } from '../services/services';
import SnackBar from '../shared/SnackBar';
import _ from 'lodash'
import './style.css'
import { useNavigate } from 'react-router';
import { FormInputDropdown } from '../shared/FormInputDropdown';
function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
        />
      </Grid>
    </React.Fragment>
  );
}

const CustomerAccountInfo = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {

  let asdasda = {
    "first_name": "Iyyappan",
    "last_name": "Sivakumar",
    "mobile_number": "7010053680",
    "email": "test@gmail.com",
    "aadhar_id": "787878787878",
    "dob": "1997-07-25",
    "gender": "male",
    "spouse_name": "Test",
    "fathers_name": "Test",
    "mothers_name": "TEst",
    "education": "TEst",
    "maritial_status": "single",
    "religion": "hindu",
    "caste": "oc",
    "physically_challenged": "yes",
    "occupation": "government",
    "residence_type": "own",
    "permenant_number": "12",
    "permenant_address1": "Test",
    "permenant_address2": "Test",
    "permenant_city_village": "Test",
    "permenant_district": "Test",
    "permenant_state": "puduchery",
    "address_same": true,
    "present_number": "12",
    "present_address1": "Test",
    "present_address2": "Test",
    "present_city_village": "Test",
    "present_district": "Test",
    "present_state": "puduchery",
    "aadhaar_proof_url": "234234234234/aadhaar.jpg",
    "pan_proof_url": "234234234234/pan.jpg",
    "driving_license_proof_url": "234234234234/driving_license.jpg",
    "passport_proof_url": "234234234234/passport.jpg",
    "voters_id_proof_url": "234234234234/voters_id.jpg",
    "family_card_proof_url": "234234234234/family_card.jpg",
    "bank_book_proof_url": "234234234234/bank_book.jpg",
    "others_proof_url": "234234234234/others.jpg",
    "net_income": "10000",
    "expenses": "10000",
    "bank_name": "Test",
    "bank_branch": "Test",
    "account_number": "1234567",
    "ifsc_code": "43434dfd",
    "guarantor_name": "Test",
    "guarantor_fathers_spouse_name": "Test",
    "guarantor_dob": "1987-12-12",
    "guarantor_gender": "male",
    "guarantor_relationship": "husband",
    "family_details": [
      { "name": "test", "age": "12", "relationship": "mother", "income": "1000", "loan_company": "tesdty" }]
  }
  useEffect(() => {
    let details = []
    // setCustomerInfo({...asdasda})
    _.map(asdasda, (v, i) => {
      details.push(i)
    })
    let a = [
      "aadhar_id",
      "account_number",
      "address_same",
      "bank_branch",
      "bank_name",
      "caste",
      "dob",
      "education",
      "email",
      "expenses",
      "fathers_name",
      "first_name",
      "gender",
      "guarantor_dob",
      "guarantor_fathers_spouse_name",
      "guarantor_gender",
      "guarantor_name",
      "guarantor_relationship",
      "ifsc_code",
      "last_name",
      "maritial_status",
      "mobile_number",
      "mothers_name",
      "net_income",
      "occupation",
      "aadhaar_proof_url",
      "pan_proof_url",
      "dirving_license_proof_url",
      "passport_proof_url",
      "voters_id_proof_url",
      "family_card_proof_url",
      "bank_book_proof_url",
      "others_proof_url",
      "permenant_address1",
      "permenant_address2",
      "permenant_city_village",
      "permenant_district",
      "permenant_number",
      "permenant_state",
      "physically_challenged",
      "present_address1",
      "present_address2",
      "present_city_village",
      "present_district",
      "present_number",
      "present_state",
      "religion",
      "residence_type",
      "spouse_name",
      "family_details"
    ]
    let b = [
      "first_name",
      "last_name",
      "mobile_number",
      "email",
      "aadhar_id",
      "dob",
      "gender",
      "spouse_name",
      "fathers_name",
      "mothers_name",
      "education",
      "maritial_status",
      "religion",
      "caste",
      "physically_challenged",
      "occupation",
      "residence_type",
      "permenant_number",
      "permenant_address1",
      "permenant_address2",
      "permenant_city_village",
      "permenant_district",
      "permenant_state",
      "address_same",
      "present_number",
      "present_address1",
      "present_address2",
      "present_city_village",
      "present_district",
      "present_state",
      "aadhaar_proof_url",
      "pan_proof_url",
      "driving_license_proof_url",
      "passport_proof_url",
      "voters_id_proof_url",
      "family_card_proof_url",
      "bank_book_proof_url",
      "others_proof_url",
      "net_income",
      "expenses",
      "bank_name",
      "bank_branch",
      "account_number",
      "ifsc_code",
      "guarantor_name",
      "guarantor_fathers_spouse_name",
      "guarantor_dob",
      "guarantor_gender",
      "guarantor_relationship",
      "family_details"
    ]
    console.log('deferent', _.difference(b, a));

  }, [])


  const initialErrorState = {
    severity: '',
    message: '',
    error: false
  }
  const customerAccountInitialState = {
    first_name: customerInfo && customerInfo.first_name ? customerInfo.first_name : '',
    last_name: customerInfo && customerInfo.last_name ? customerInfo.last_name : '',
    mobile_number: customerInfo && customerInfo.mobile_number ? customerInfo.mobile_number : '',
    email: customerInfo && customerInfo.email ? customerInfo.email : '',
    dob: customerInfo && customerInfo.dob ? customerInfo.dob : '',
    gender: customerInfo && customerInfo.gender ? customerInfo.gender : '',
    aadhar_id: customerInfo && customerInfo.aadhar_id ? customerInfo.aadhar_id : ''
  };

  const genderList = [
    {
      label: "Male",
      value: "male"
    },
    {
      label: "Female",
      value: "female"
    },
    {
      label: "Transgender",
      value: "transgender"
    }
  ]
  const [accountInfo] = useState(customerAccountInitialState);
  const [apiError, setApiError] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { ...accountInfo }
  });

  const onSubmit = (data) => {
    console.log('datadatadata', data);
    console.log(customerInfo)
    if (Object.keys(customerInfo).length === 0) {
      // let queryParams = `aadhar_id=${data.aadhar_id}`
      services.postService(`customer/search`, { aadhar_id: data.aadhar_id })
        .then(res => {
          console.log('datsdsa', res)
          if (res?.length === 0 || res.total === 0) {
            let date = data?.dob?.toISOString().split("T")[0];
            data.dob = date;
            console.log('datacomvert', data);
            setCustomerInfo({
              ...customerInfo, ...data
            });
            handleNext(false);
          } else {
            setOpen(true);
            setApiError({
              severity: "error",
              message: `Validation Error :: Aadhar number & Email already exists.`,
              error: true,
              id: _.get(res, 'data[0].id')
            })
          }
        })
        .catch(err => {
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
        })
    } else {
      setCustomerInfo({
        ...customerInfo, ...data
      });
      handleNext(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setApiError(initialErrorState)
  };

  React.useEffect(() => {
    !apiError.error && reset(customerInfo)
  }, [customerInfo])


  return (
    <>
      {
        apiError.error && <div>
          <SnackBar open={open} id={_.get(apiError, 'id')} message={apiError.message} handleClose={handleClose} severity={apiError.severity} />
        </div>
      }
      <form onSubmit={handleSubmit(onSubmit)} >
        <Card>
          <CardHeader
            title="Account Information"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid container item spacing={2} >
                <FormRow control={control} name="first_name" label="First Name" rules={CustomerRules.first_name} />
                <FormRow control={control} name="last_name" label="Last Name" rules={CustomerRules.last_name} />
              </Grid>
              <Grid container item spacing={2} >
                <FormRow control={control} inputType="number" name="mobile_number" label="Mobile number" rules={CustomerRules.mobile_number} />
                <FormRow control={control} name="email" label="Email" rules={CustomerRules.email} inputType="email" />
              </Grid>
              <Grid container item spacing={2} >
                <FormRow control={control} name="aadhar_id" label="Aadhar ID" inputType="number" rules={CustomerRules.aadhar_id} />

                <Grid item
                  md={6}
                  xs={10}
                  lg={4}
                >

                  <PastInputDate control={control} name="dob" label="DOB" rules={CustomerRules.dob} required={true} />    </Grid>
              </Grid>

              <Grid container item spacing={2} >
                <Grid item
                  md={6}
                  xs={10}
                  lg={4}
                >
                  <FormInputDropdown name="gender" control={control} label="Gender" options={genderList} rules={CustomerRules.gender} />
                  {/* <FormInputRadio
                    name="gender"
                    control={control}
                    label="Gender"
                    options={genderList}
                    rules={CustomerRules.gender}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              href="/app/customers"
              style={{ height: '36px' }}
            >
              Cancel
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                  Previous
                </Button>
              )}

              <Button
                variant="contained"
                sx={{ mt: 2, ml: 1 }}
                type="submit"
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
              </Button>
            </Box>
          </Box>
        </Card>
      </form>
    </>
  );
};


export default CustomerAccountInfo;
