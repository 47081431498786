import React, { useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
// import { FormInputRadio } from '../shared/FormInputRadio';
import { FormInputDropdown } from '../shared/FormInputDropdown';

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
        />
      </Grid>
    </React.Fragment>
  );
}

function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item
      md={6}
      xs={10}
      lg={4}
    >
      <FormInputDropdown name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
      {/* <FormInputRadio
          name={name}
          control={control}
          label={label}
          options={options}
          rules={rules}
        /> */}
    </Grid>
  );
}

const CustomerPersonalInfo = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
  const selectValues = (value) => {
    if (value === 0) {
      return 'no'
    } else if (value === 1) {
      return 'yes'
    } else {
      return value
    }
  }
  const customerPersonalInfoInitialState = {
    spouse_name: customerInfo && customerInfo.spouse_name ? customerInfo.spouse_name : '',
    fathers_name: customerInfo && customerInfo.fathers_name ? customerInfo.fathers_name : '',
    mothers_name: customerInfo && customerInfo.mothers_name ? customerInfo.mothers_name : '',
    education: customerInfo && customerInfo.education ? customerInfo.education : '',
    maritial_status: customerInfo && customerInfo.maritial_status ? customerInfo.maritial_status : '',
    religion: customerInfo && customerInfo.religion ? customerInfo.religion : '',
    caste: customerInfo && customerInfo.caste ? customerInfo.caste : '',
    physically_challenged: customerInfo && selectValues(customerInfo.physically_challenged) ? selectValues(customerInfo.physically_challenged) : '',
    occupation: customerInfo && customerInfo.occupation ? customerInfo.occupation : '',
    residence_type: customerInfo && customerInfo.residence_type ? customerInfo.residence_type : ''
  };

  const [personalInfo] = useState(customerPersonalInfoInitialState);

  const { handleSubmit, control } = useForm({
    defaultValues: { ...personalInfo }
  });

  const maritialStatusOptions = [
    {
      label: 'Unmarried',
      value: 'Unmarried'
    },
    {
      label: 'Married',
      value: 'Married'
    },
    {
      label: 'Divorced',
      value: 'divorced'
    },
    {
      label: 'Widow',
      value: 'widow'
    }
  ]

  const ReligionOptions = [
    {
      label: 'Hindu',
      value: 'hindu'
    },
    {
      label: 'Muslim',
      value: 'muslim'
    },
    {
      label: 'Christian',
      value: 'christian'
    },
    {
      label: 'Others',
      value: 'others'
    }
  ]

  const educationOptions = [
    {
      label: 'Undergraduate',
      value: 'undergraduate'
    },
    {
      label: 'PostGraduate',
      value: 'postgraduate'
    },
    {
      label: 'Graduate',
      value: 'graduate'
    }
  ]

  const CasteOptions = [
    {
      label: 'OC',
      value: 'oc'
    },
    {
      label: 'OBC',
      value: 'obc'
    },
    {
      label: 'MBC',
      value: 'mbc'
    },
    {
      label: 'SC',
      value: 'sc'
    },
    {
      label: 'ST',
      value: 'st'
    },
    {
      label: 'Others',
      value: 'others'
    }
  ]

  const PCOptions = [

    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'Yes',
      value: 'yes'
    },
  ]

  // const ResidentOptions = [
  //   {
  //     label: 'Own',
  //     value: 'own'
  //   },
  //   {
  //     label: 'Rent',
  //     value: 'rent'
  //   },
  //   {
  //     label: 'Lease',
  //     value: 'lease'
  //   }
  // ]

  const OccupationOptions = [
    {
      label: 'Central Government',
      value: 'central government'
    },
    {
      label: 'State Government',
      value: 'state government'
    },
    {
      label: 'Self Employee',
      value: 'self employee'
    },
    {
      label: 'Business',
      value: 'business'
    },
    {
      label: 'Others',
      value: 'others'
    }
  ]

  const onSubmit = (data) => {
    setCustomerInfo({
      ...customerInfo, ...data
    });
    handleNext(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >

      <Card>
        <CardHeader
          title="Personal Information"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item spacing={2} >
              <FormRow control={control} name="spouse_name" label="Spouse name" rules={CustomerRules.spouse_name} readonly={isNextActive} />
              <FormRow control={control} name="fathers_name" label="Father's Name" rules={CustomerRules.fathers_name} readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} >
              <FormRow control={control} name="mothers_name" label="Mother's name" rules={CustomerRules.mothers_name} readonly={isNextActive} />
              <FormRowRadio
                name="education"
                control={control}
                label="Education"
                options={educationOptions} rules={CustomerRules.education} readonly={isNextActive}
              />
            </Grid>
            <Grid container item spacing={2} >
              <FormRowRadio
                name="maritial_status"
                control={control}
                label="Maritial Status"
                options={maritialStatusOptions} rules={CustomerRules.maritial_status} readonly={isNextActive}
              />
              <FormRowRadio
                name="religion"
                control={control}
                label="Religion"
                options={ReligionOptions} rules={CustomerRules.religion} readonly={isNextActive}
              />

            </Grid>
            <Grid container item spacing={2} >
              <FormRowRadio
                name="caste"
                control={control}
                label="Caste"
                options={CasteOptions} rules={CustomerRules.caste} readonly={isNextActive}
              />
              <FormRowRadio
                name="physically_challenged"
                control={control}
                label="Physically Challenged ?"
                options={PCOptions} rules={CustomerRules.physically_challenged} readonly={isNextActive}
              />

            </Grid>
            <Grid container item spacing={2} >
              <FormRowRadio
                name="occupation"
                control={control}
                label="Occupation"
                options={OccupationOptions} rules={CustomerRules.occupation} readonly={isNextActive}
              />


              {/* <FormRowRadio
  name="residence_type"
  control={control}
  label="Residence Type"
  options={ResidentOptions} rules={CustomerRules.residence_type}
/> */}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: '36px' }}
          >
            Cancel
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                Previous
              </Button>
            )}

            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
            </Button>
          </Box>
        </Box>
      </Card>
    </form>
  );
};


export default CustomerPersonalInfo;
