import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  IconButton
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BreadcrumbsComponent from '../Breadcrumbs';
import { useState } from 'react';
const breadCrumbsData = [{ name: 'Customers', path: '/app/customers' }]

const CustomerListToolbar = ({
  handleChange,
  handleSearch,
  resetFields
}) => {

  const [first, setfirst] = useState('')
  const [first1, setfirst1] = useState('')
  const [first2, setfirst2] = useState('')


  const resetValues = () => {
    setfirst('')
    setfirst1('')
    setfirst2('')
    resetFields()
  }
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearch()
      // put the login here
    }
  }
  return (
    <Box>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}> <BreadcrumbsComponent data={breadCrumbsData} />
        <Box
          sx={{
          }}
        >
          <Button
            color="primary"
            variant="contained"
            href="/app/customers/add"
          >
            Add customer
          </Button>
        </Box></div>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={11}>
                <Grid container spacing={1}>
                  <Grid item md={3}>
                    <Box >
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon
                                fontSize="small"
                                color="action"
                              >
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}

                        placeholder="Search with Aadhar"
                        variant="outlined"
                        value={first}
                        type='number'
                        onChange={(e) => { handleChange(e); setfirst(e.target.value) }}
                        name="aadhar_id"
                        onKeyDown={(e) => keyPress(e)}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box >
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon
                                fontSize="small"
                                color="action"
                              >
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        placeholder="Search with Name"
                        variant="outlined"
                        value={first1}
                        onChange={(e) => { handleChange(e); setfirst1(e.target.value) }}
                        name="name"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box >
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon
                                fontSize="small"
                                color="action"
                              >
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        placeholder="Search with Email"
                        variant="outlined"
                        value={first2}
                        onChange={(e) => { handleChange(e); setfirst2(e.target.value) }}
                        name="email"
                        type="email"

                      />
                    </Box>
                  </Grid>
                  {/* <Grid item md={3}>
                  <Box >
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search with Status"
                      variant="outlined"
                      onChange={handleChange}
                      name="status"
                    />
                  </Box>
                </Grid> */}
                </Grid>
              </Grid>
              <Grid item md={1}>
                <Box>
                  <IconButton onClick={handleSearch}>
                    <ArrowCircleRightSharpIcon fontSize="large" />
                  </IconButton>
                  <IconButton onClick={resetValues}>
                    <RestartAltIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default CustomerListToolbar;
