import React, { useState } from 'react';

import {
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
    Button
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../components/shared/FormInputText';

function FormRow(props) {
    const { control, name, label, rules, inputType, readonly } = props;
    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={4}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    helperText={props.helperText || null}
                />
            </Grid>
        </React.Fragment>
    );
}

const IncomeDetailsLoan = ({
    handleBack,
    handleNext,
    activeStep,
    steps,
    setloanInfo,
    loanInfo,
    isNextActive
}) => {
    const defaultValues = {
        "house_rent": loanInfo.house_rent || '',
        "eb_charges": loanInfo.eb_charges || '',
        "education_expenses": loanInfo.education_expenses || '',
        "agriculture_expenses": loanInfo.agriculture_expenses || '',
        "food_dresses_maintenance_expenses": loanInfo.food_dresses_maintenance_expenses || '',
        "medical_expenses": loanInfo.medical_expenses || '',
        "business_expenses": loanInfo.business_expenses || '',
        "other_expenses": loanInfo.other_expenses || '',
        "loan_installments": loanInfo.loan_installments || '',
        "total_household_income": loanInfo.total_household_income || '',
        "total_household_expense": loanInfo.total_household_expense || '',
        "total_household_income_net": loanInfo.total_household_income_net || '',
    };
    const [incomeInfo, setincomeInfo] = useState(defaultValues);

    const { handleSubmit, control } = useForm({
        defaultValues: { ...incomeInfo }
    });

    const onSubmit = (data) => {
        console.log("dataonsubmit", data);
        setincomeInfo({
            ...loanInfo, ...data
        })
        handleNext();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Card>
                <CardHeader
                    title="Income Details"
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={2} >
                        <Grid
                            container
                            item
                            spacing={2}
                        >
                            <FormRow control={control} inputType="number" name="house_rent" label="House Rent" rules={{ required: CustomerRules.commonValidation("House Rent") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="eb_charges" label="EB Charges" rules={{ required: CustomerRules.commonValidation("EB Charges") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="education_expenses" label="Education Expenses" rules={{ required: CustomerRules.commonValidation("Education Expenses") }} readonly={isNextActive} />
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                        >
                            <FormRow control={control} inputType="number" name="agriculture_expenses" label="Agriculture Expenses" rules={{ required: CustomerRules.commonValidation("Agriculture Expenses") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="food_dresses_maintenance_expenses" label="Food Dresses Maintenance Expenses" rules={{ required: CustomerRules.commonValidation("Food Dresses Maintenance Expenses") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="medical_expenses" label="Medical Expenses" rules={{ required: CustomerRules.commonValidation("Medical Expenses") }} readonly={isNextActive} />
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                        >
                            <FormRow control={control} inputType="number" name="business_expenses" label="Business Expenses" rules={{ required: CustomerRules.commonValidation("Business Expenses") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="loan_installments" label="Loan Installments" rules={{ required: CustomerRules.commonValidation("Loan Installments") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="total_household_income" label="Total Household Income" rules={{ required: CustomerRules.commonValidation("Total Household Income") }} readonly={isNextActive} />
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                        >
                            <FormRow control={control} inputType="number" name="total_household_expense" label="Total Household Expense" rules={{ required: CustomerRules.commonValidation("Total Household Expense") }} readonly={isNextActive} />
                            <FormRow control={control} inputType="number" name="total_household_income_net" label="Total Household Income Net" rules={{ required: CustomerRules.commonValidation("Total Household Income Net") }} readonly={isNextActive} />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ mt: 2, ml: 1 }}
                        href="/app/customers"
                        style={{ height: '36px' }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>  {activeStep !== 0 && (
                        <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                            Previous
                        </Button>
                    )}
                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            type="submit"
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
                        </Button>
                    </Box>
                </Box>
            </Card>
        </form>
    );
};


export default IncomeDetailsLoan;
