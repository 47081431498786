import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Container,
  Button
} from '@mui/material';
// import { saveAs } from 'file-saver';

import { useForm } from 'react-hook-form';
import { FormInputText } from '../components/shared/FormInputText';
// import bcrypt from 'bcryptjs'
import { FormInputSwitch } from 'src/components/shared/FormInputSwitch';
import SnackBar from 'src/components/shared/SnackBar';
import { UserSuccessMessage } from 'src/utils/ErrorMessages';
import { services } from 'src/components/services/services';
import { Link } from 'react-router-dom';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import _ from 'lodash'
// import { message } from 'antd';
import { FormInputCheckboxGroup } from 'src/components/shared/FormInputCheckboxGroup';
import { UserRules } from '../utils/validationsConfig';

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly,is_password=false,show_password=false,handleClickShowPassword,onChange } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          show_password={show_password}
          is_password={is_password}
          handleClickShowPassword={handleClickShowPassword}
          onChangeText={(value)=>onChange(value,name)}
        />
      </Grid>
    </React.Fragment>
  );
}



const AddEditUser = () => {
  // const {watch} =useForm()
  let { id } = useParams();
  const[pwd,setPwd]=useState('')
  const userInitialState = {
    name: '',
    username: '',
    email: '',
    password: '',
    mobile:'',
    role: '',
    status: true
  };
  const initialErrorState = {
    severity: '',
    message: '',
    error: false
  }
  let [userDetails, setUserDetails] = useState(userInitialState);
  let [rolesList, setRolesList] = useState({});
  let [apiError, setApiError] = useState(initialErrorState);
  const [isUserAdded, setIsUserAdded] = useState(false);
  const [open, setOpen] = useState(false);
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
// const handleMouseDownPassword = () => setShowPassword(!showPassword);
const history =useNavigate()
  useEffect(()=>{
if(!localStorage.getItem('token')){
  history('/login')
}
  },[])
  const { handleSubmit, control, reset } = useForm({
    defaultValues: { ...userDetails }
  });

  const getRoles = async () => {
    try {
      let roles = await services.getService(`role/list`);
      console.log('asdnsadassds',roles.data);
      let list = roles.data.map(role => { return { label: role.value, value: role.key } });
      return list;
    } catch (err) {
      console.log(err);
    }
  }

  const getUser = async () => {
    try {
      let userDetails = await services.getService(`user/details/${id}`);
      return userDetails.data;
    } catch (err) {
      console.log(err);
    }
  }

  const createNewUser = async (data) => {
    // const salt = bcrypt.genSaltSync(10);
    data = {
      ...data
    }
    // bcrypt.hashSync(data.password, salt)
    try {
      let response = await services.postService(`user/add`, data);
      response.status && setIsUserAdded(true) && setUserDetails(userInitialState);
      return response.status;
    } catch (err) {
      let errors = err && err.data && err.data.results;
      errors && setApiError({
        severity: "error",
        message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
        error: true
      })
      throw err;
    }
  }

  const updateUser = async (data) => {
    try {
      let response = await services.putService(`user/edit/${id}`, data)
      response.status && setIsUserAdded(true) && setUserDetails(userInitialState);
      return response.status;
    }catch(err) {
      setApiError({
        severity: "error",
        message: `Error :: Please try again!`,
        error: true
      })
    }
  }

  useEffect(() => {
    getRoles().then(res => {
      console.log('Roles list',res);
      setRolesList(res);
    });

    id !== undefined && getUser().then(details => setUserDetails({
      ...userDetails,
      ...details
    })).catch(err => {
      console.log(err);
      console.log(err.response);
    })

  }, []);

  useEffect(() => {
    !apiError.error && reset(userDetails)
  }, [userDetails])

  const onSubmit = (data) => {
    console.log('datadata',data);
    delete data.confirm_password
    if (id === undefined) {
      createNewUser(data)
      .then(res => {
        
        res && setOpen(true)
        history('/app/users')
      }
        
        )
      .catch(err => err && setOpen(true))
    } else {
      // let a ={...data}
    if(!_.get(data,'password')){
      delete data.password
    }
           updateUser(data)
        .then(response => {
          response && setOpen(true)
          history('/app/users')
        }
          )
        .catch(err => err && setOpen(true))
    }
  };
  // const props = {
  //   name: 'file',

  //   onChange(info) {
  //     if (info.file.status !=== 'uploading') {
  //       console.log(info.file, info.fileList);
  //       let photo =''
  //       const reader = new FileReader();
  //                       reader.readAsDataURL(_.get(info,'file.originFileObj'));
  //                       reader.addEventListener("load", () => {
  //                         if (reader.result) {
                           
  //                           photo = reader.result;
  //                           console.log('photophoto',reader.result);
  //                           // saveAs(reader.result)
  //                         }
  //                       });
                       
  //     }
  //     if (info.file.status ==== 'done') {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status ==== 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   showUploadList: {
  //     showRemoveIcon:false,
  //     showDownloadIcon: true,
  //     downloadIcon: (value)=>console.log('valuevalue',value),
      
      
  //   }
  // };
  const handleClose = () => {
    setOpen(false);
    setApiError(initialErrorState)
  };
  const breadCrumbsData =[{name:'Users',path:'/app/users'},{name:id?'Update': 'Add',path:id?`/app/users/${id}`: '/app/users/add'}]
//   const dummyRequest = ({ file, onSuccess }) => {
//     setTimeout(() => {
//         onSuccess("ok");
//     }, 50);
// };

// const downlodFile =(file)=>{
//   let photo =''
//         const reader = new FileReader();
//                         reader.readAsDataURL(_.get(file,'originFileObj'));
//                         reader.addEventListener("load", () => {
//                           if (reader.result) {
                           
//                             photo = reader.result;
//                             console.log('photophoto',reader.result);
//                             saveAs(reader.result)
//                           }
//                         });
// }
  return <>
    {
      isUserAdded && <SnackBar open={open} message={UserSuccessMessage.ADD} handleClose={handleClose} severity="success" />
    }
    {
      apiError.error && <SnackBar open={open} message={apiError.message} handleClose={handleClose} severity={apiError.severity} />
    }
    {
      rolesList && rolesList.length > 0 && <>
        <Helmet>
        <title>{id?'Edit': 'Add'} User - Thangam Capital </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth="lg">
          <BreadcrumbsComponent data={breadCrumbsData}/>
          {/* <Upload  onDownload={(fie)=>downlodFile(fie)} {...props} customRequest={dummyRequest}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload> */}
            <Box sx={{ pt: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)} >
                <Card>
                  <CardHeader
                    title={id !== undefined ? "Edit User" : "Add User"}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid container item spacing={2}>
                        <FormRow control={control} name="name" label="Full Name" rules={UserRules.fullName} />
                        <FormRow control={control} name="username" label="Username" rules={UserRules.username} readonly={id===undefined ? false: true} />
                        <FormRow control={control} name="email" label="Email" rules={UserRules.email} inputType="email" />
                        
  
                      </Grid>
                      <Grid container item spacing={2}>
                    
                      <FormRow control={control} inputType="tel" name="mobile_number"  label="Mobile Number" rules={UserRules.mobile_number}  />
                          <FormRow
                          onChange={(value,type)=>type==='password' && setPwd(value)}
                          show_password={showPassword} is_password={true} control={control} handleClickShowPassword={handleClickShowPassword} name="password" label="Password" rules={ id?UserRules.password_type: UserRules.password} inputType="password" />
                         <FormRow show_password={showPassword} 
                          
                          is_password={true} control={control} handleClickShowPassword={handleClickShowPassword} 
                          name="confirm_password" label="Confirm Password" rules={ id?pwd? {validate: value => value === pwd || "The passwords do not match"}: UserRules.password_type: {validate: value => value === pwd || "The passwords do not match"}} 
                          inputType="password" />
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid item
                          md={6}
                          xs={10}
                          lg={4}
                        >
                          <FormInputCheckboxGroup
                            name="role"
                            control={control}
                            label="Choose any one Roles"
                            options={rolesList}
                            rules={UserRules.role_id}
                            multiple
                          />
                          
                          {/* <FormInputDropdown
                            name="role"
                            control={control}
                            label="Choose any one Role"
                            options={rolesList}
                            rules={UserRules.role_id}
                          /> */}
                          {/* <FormInputRadio
                            name="role"
                            control={control}
                            label="Choose any one Role"
                            options={rolesList}
                            rules={UserRules.role_id}
                          /> */}
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid item
                          md={6}
                          xs={10}
                          lg={4}
                        >
                          <FormInputSwitch control={control} name="status" label="Status" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/app/users`}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {id?'Update': 'Add'}
                    </Button>
                  </Box>
                </Card>
              </form>
            </Box>
          </Container>
        </Box>
      </>
    }
  </>;
}

export default AddEditUser;
