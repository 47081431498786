import React, { useState, useEffect } from 'react';

import {
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
    Button
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ProofImageUpload } from '../components/shared/proff-image-upload';
// import { styled } from '@mui/material/styles';
import { DeleteFilled, DownCircleOutlined, EyeFilled } from '@ant-design/icons';

import _ from 'lodash'
// import {notification,Spin} from 'antd'
import './style.css'
import axios from "axios"
import { FormInputText } from '../components/shared/FormInputText';
import { FormInputDropdown } from '../components/shared/FormInputDropdown';
import Icon from '@mui/material/Icon';
import { CustomerRules } from 'src/utils/validationsConfig';
import SnackBar from '../components/shared/SnackBar';

function FormRow(props) {
    const { control, name, label, rules, inputType, readonly } = props;
    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={4}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    helperText={props.helperText || null}
                    setAddressInfo={props.setAddressInfo}
                    addressInfo={props.addressInfo}
                />
            </Grid>
        </React.Fragment>
    );
}
const ProofDetailsLoan = ({
    handleBack,
    handleNext,
    activeStep,
    steps,
    setloanInfo,
    loanInfo,
    isNextActive
}) => {
    // let proofs_data = [
    //   "aadhaar_proof_url",
    //   'pan_proof_url',
    //   'dirving_license_proof_url',
    //   'passport_proof_url',
    //   'voters_id_proof_url',
    //   'family_card_proof_url',
    //   'bank_book_proof_url',
    //   'others_proof_url',
    // ]
    console.log('loanInfoloanInfo', loanInfo);
    const [urlImage, seturlImage] = useState('')
    const FormRowUpload = (props) => {
        const { control, name, label, rules, id, type_dropdown, image_Details, readonly } = props;


        return (
            <React.Fragment>

                <Grid item md={10} xs={10} lg={10}  >
                    {/* {_.get(loanInfo,image_name) && 
        <div style={{    marginLeft: "48%",fontSize: "23px",marginBottom: "-19px"}}>
          <EyeFilled  onClick={()=>viewImage(_.get(loanInfo,image_name),image_name)} />
          <DownCircleOutlined style={{marginLeft:23}}  onClick={()=>viewImage(_.get(loanInfo,image_name),image_name,'down')} /> </div>} */}
                    <ProofImageUpload
                        imageChange={(values) => {
                            imageUploadNew(values, name)

                        }}
                        id={id}
                        show_upload={image_Details}
                        readonly={readonly || false}
                        value={setImageValue ? setImageValue : null}
                        newFieldTru={(va) => {
                            setsetuploadvalue(true);
                            setsetImageValue({ file: va, type_name: name, id: id, })
                            imageUploadNew(va, getValues('proof'), id, type_dropdown)

                        }}
                        name={name} label={label} control={control} rules={rules} />

                </Grid>
            </React.Fragment>
        );
    }
    const imageUploadNew = (value, name, id, type_dropdown) => {
        postService(value, name, id, type_dropdown)
    }



    {/*
    const viewImage = (value, name, type) => {
        console.log('value,name,type', value, name, type);
        axios({
            method: 'get',
            url: `http://thangamcapitaladmin-ui.s3-website.ap-south-1.amazonaws.com/qa/read-file`,
            data: { "fileKey": value },
            params: { "fileKey": value },
            headers: {
                // Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then((res) => {
            console.log('View presignedUrl', res);
            if (type === 'down') {
                fetch(_.get(res, 'data.presignedUrl'))
                    .then(response => {
                        response.arrayBuffer().then(function (buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", value); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                // setPrevImage(_.get(res,'data.presignedUrl'))
                // setPrevImageModal(true)

            }

        })
    }
        */}

    let toMe = [

        { label: 'Aadhaar', value: "aadhaar_proof_url" },
        { label: 'Pan', value: "pan_proof_url" },
        { label: 'Driving License', value: "dirving_license_proof_url" },
        { label: 'Passport', value: "passport_proof_url" },
        { label: 'Voters Id', value: "voters_id_proof_url" },
        { label: 'Family Card', value: "family_card_proof_url" },
        { label: 'Bank Book', value: "bank_book_proof_url" },
        { label: 'Others', value: "others_proof_url" },
    ]

    const [profileSelectDetails, setProfileSelectDetails] = useState([

        { label: 'Aadhaar', value: "aadhaar_proof_url" },
        { label: 'Pan', value: "pan_proof_url" },
        { label: 'Driving License', value: "dirving_license_proof_url" },
        { label: 'Passport', value: "passport_proof_url" },
        { label: 'Voters Id', value: "voters_id_proof_url" },
        { label: 'Family Card', value: "family_card_proof_url" },
        { label: 'Bank Book', value: "bank_book_proof_url" },
        { label: 'Others', value: "others_proof_url" },
    ])
    const [profileSelectDetails1, setProfileSelectDetails1] = useState([

        { label: 'Aadhaar', value: "aadhaar_proof_url" },
        { label: 'Pan', value: "pan_proof_url" },
        { label: 'Driving License', value: "dirving_license_proof_url" },
        { label: 'Passport', value: "passport_proof_url" },
        { label: 'Voters Id', value: "voters_id_proof_url" },
        { label: 'Family Card', value: "family_card_proof_url" },
        { label: 'Bank Book', value: "bank_book_proof_url" },
        { label: 'Others', value: "others_proof_url" },
    ])

    const initialErrorState = {
        severity: '',
        message: '',
        error: false,
        show: false
    }
    const [setuploadvalue, setsetuploadvalue] = useState(false)
    const [formList, setFormList] = useState(_.isArray(_.get(loanInfo, 'id_proof')) === true && _.size(_.get(loanInfo, 'id_proof')) > 0 ? [..._.get(loanInfo, 'id_proof')] : [
        { proof: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }
    ])
    const [addressProof, setaddressProof] = useState(_.isArray(_.get(loanInfo, 'addres_proof')) === true && _.size(_.get(loanInfo, 'addres_proof')) > 0 ? [..._.get(loanInfo, 'addres_proof')] : [
        { proof_address: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }
    ])
    const [apiError, setApiError] = useState(initialErrorState);
    // const [open, setOpen] = useState(false);
    const [setImageValue, setsetImageValue] = useState({})
    const defaultValues = {
        aadhaar_proof_url: "",
        pan_proof_url: "",
        driving_license_proof_url: "",
        passport_proof_url: "",
        voters_id: "",
        family_card: "",
        bank_book: "",
        others: "",
        proof: "",
        proof_unique_no: "",
        proof_image: null,
        proof_address: "",
        proof_unique_no_address: "",
        proof_image_address: null

    };
    // let final_try ={}
    const { handleSubmit, control, reset, formState: { isValid }, getValues } = useForm({
        defaultValues: { ...defaultValues }
    });

    useEffect(() => {
        if (loanInfo) {
        }

    }, [])

    // const postFiles = async (f, url, k, name, id, type_dropdown) => {
    //     console.log('f,url,k,name,id,type_dropdown', f, url, k, name, id, type_dropdown);

    //     const formData = new FormData();
    //     formData.append('file', f);
    //     axios({
    //         method: 'put',
    //         url: url,
    //         data: f,
    //     }).then(async (res) => {
    //         seturlImage(k)
    //     }).catch((err) => {

    //     })



    // }
    useEffect(() => {
        if (loanInfo) {
            let pick_details = _.pick(loanInfo, [
                "aadhaar_proof_url",
                'pan_proof_url',
                'dirving_license_proof_url',
                'passport_proof_url',
                'voters_id_proof_url',
                'family_card_proof_url',
                'bank_book_proof_url',
                'others_proof_url',])

            let a = [{ proof: "aadhaar_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "aadhaar_proof_url") ? true : false, id: 1 },
            { proof: "pan_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "pan_proof_url") ? true : false, id: 1 },
            { proof: "dirving_license_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "dirving_license_proof_url") ? true : false, id: 1 },
            { proof: "passport_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "passport_proof_url") ? true : false, id: 1 },
            { proof: "voters_id_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "voters_id_proof_url") ? true : false, id: 1 },
            { proof: "family_card_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "family_card_proof_url") ? true : false, id: 1 },
            { proof: "bank_book_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "bank_book_proof_url") ? true : false, id: 1 },
            { proof: "others_proof_url", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "others_proof_url") ? true : false, id: 1 },


            ]
            let b = []
            _.map(a, (v, i) => {
                if (v.upload === true) {
                    b.push(v)
                }

            })
            if (_.size(b) === 8) {
                // setbtnType('next')
            }
            // setFormList(_.size(b)===8?b:_.size(b)>1?[...b, {proof:"proof",name2:'proof_unique_no',imageurl:'',upload:false,id:1}]:b)
        }
    }, [])


    const postService = async (service, key, id, type_dropdown) => {
        console.log('service, keyservice, key', service, key);
        let split = _.split(_.get(service, 'name'), '.')[1]
        // setImageLoading(true)
        axios({
            method: 'post',
            url: `https://1sjtwuxmcl.execute-api.ap-south-1.amazonaws.com/qa/upload-file`,
            data: { customerId: _.get(loanInfo, 'aadhar_id'), fileName: `${key}.${split}` },
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            let k = `${_.get(loanInfo, 'aadhar_id')}/${key}_${type_dropdown}.${split}`

            // postFiles(service, _.get(res, 'data.presignedUrl'), k, key, id, type_dropdown)
            // setImageLoading(false)

        }).catch((err) => {
            // setImageLoading(false)
        })

    }
    const onSubmit = async (data) => {
        console.log('addressProofaddressProof1 test', addressProof, formList);

        let address_a = [...addressProof]
        setloanInfo({
            ...loanInfo,
            addres_proof: addressProof,
            id_proof: formList
        })

        console.log('addressProofaddressProof2 test', addressProof, formList);
        handleNext()

        // }

    }
    const deleteItem = (item, type) => {
        if (type === "address") {
            let a = [...addressProof]
            let b = []
            // let d =[]
            _.map(a, (v, i) => {
                if (_.get(v, 'proof_address') !== _.get(item, 'proof_address') && _.get(v, 'proof_address') !== "proof") {
                    b.push({ ...v, value: _.get(v, 'proof_address') });
                }
            })

            // setbtnType('add')

            setaddressProof([...b, { proof_address: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
            filderBox([...b], item, 'address')
        } else {
            let a = [...formList]
            let b = []
            // let d =[]
            _.map(a, (v, i) => {
                if (_.get(v, 'proof') !== _.get(item, 'proof') && _.get(v, 'proof') !== "proof") {
                    b.push({ ...v, value: _.get(v, 'proof') });
                }
            })

            // setbtnType('add')

            setFormList([...b, { proof: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
            filderBox([...b], item)
        }

    }
    const filderBox = (value, item, type) => {
        if (type) {
            setProfileSelectDetails1(_.xorBy(value, toMe, 'value'))
        } else {
            setProfileSelectDetails(_.xorBy(value, toMe, 'value'))
        }

    }
    const handleAdd = (e, type) => {
        if (type) {


            let pick_detail = _.pick(getValues(), ['proof_address', 'proof_unique_no_address', 'proof_image_address'])
            console.log('pick_detail', pick_detail, getValues(), addressProof);
            let details = [...addressProof, { ...pick_detail, upload: true, url: urlImage }]
            let filder = _.filter(details, (v, i) => v.upload === true)
            setaddressProof([..._.uniqBy(filder, 'proof_address'), { proof_address: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
            // e.preventDefault();
            reset()
            setsetuploadvalue(false)
            setsetImageValue({})
            let adc = [...profileSelectDetails1]
            let filter_value = _.filter(adc, (v, i) => v.value !== pick_detail?.proof_address)
            setProfileSelectDetails1(filter_value)
            console.log('addressProofaddressProof 3 test', filder);
        } else {
            let pick_detail = _.pick(getValues(), ['proof', 'proof_unique_no', 'proof_image'])
            let details = [...formList, { ...pick_detail, upload: true, url: urlImage }]
            let filder = _.filter(details, (v, i) => v.upload === true)
            setFormList([..._.uniqBy(filder, 'proof'), { proof: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
            // e.preventDefault();
            reset()
            setsetuploadvalue(false)
            setsetImageValue({})
            let adc = [...profileSelectDetails]
            let filter_value = _.filter(adc, (v, i) => v.value !== pick_detail?.proof)
            setProfileSelectDetails(filter_value)
            console.log('addressProofaddressProof 4 test', filder);
        }


    }
    useEffect(() => {
        console.log('sd,mf,msdsfsdfd,', getValues('proof_image'));
    }, [getValues('proof_image')])

    const commanNameChange = (name) => {
        if (`${name}`.includes('_url')) {
            return _.startCase(_.split(name, '_url')[0])
        } else {
            return _.startCase(name)
        }

    }
    const loadFormView = (formList) => {
        console.log('sd,mf,msd,', getValues('proof_image'));
        if (_.size(formList) === 1) {

            return <Grid container item spacing={1}>
                <FormRow control={control} readonly={getValues('proof')} name="proof_unique_no" label="" rules={{ required: CustomerRules.commonValidation("Proof Unique No") }} />
                <Grid item md={6} xs={10} lg={3} >
                    <FormRowUpload name_title={getValues('proof')} value_detail={formList[0]?.image_Details} name="proof_image" id='12' type_dropdown="id_proof" disabled={getValues('proof_unique_no') ? false : true} label="Aadhaar" control={control} readonly={isNextActive} />
                </Grid>
                <Grid item md={6} xs={10} lg={2} >
                    {/* <PlusCircleFilled type="" className='add_proof_icon' size={40} style={{width:34,height:'45px',cursor:'pointer'}}  />  */}
                    <Icon baseClassName="fas" style={{ marginTop: 5, fontSize: 28, color: setuploadvalue === false && _.get(setImageValue, 'type_name') !== "proof_image" && _.get(setImageValue, 'id') !== 12 ? "#cccccc" : _.get(setImageValue, 'type_name') === "proof_image" && _.get(setImageValue, 'id') === 12 ? "#4984BF" : "#cccccc" }} className="fa-plus-circle" color="primary" onClick={() => setuploadvalue === true
                        && _.get(setImageValue, 'type_name') === "proof_image" && _.get(setImageValue, 'id') === 12
                        && handleAdd()} />
                </Grid>

            </Grid>
        } else {
            return _.map(formList, (v, i) => {
                console.log('formListformList', formList);
                return _.get(v, 'upload') === true ? <Grid style={{ marginBottom: 34, marginTop: 10 }} key={i} container item spacing={1}>
                    <Grid item md={6} xs={10} lg={3} >
                        <div>{commanNameChange(_.get(v, 'proof'))}</div>
                    </Grid>
                    <Grid item md={6} xs={10} lg={3} >
                        <div>{_.get(v, 'proof_unique_no')}</div>
                    </Grid>
                    <Grid item md={6} xs={10} lg={3} >
                        <div>{_.get(v, 'proof_image.name')}.png</div>
                    </Grid>
                    <Grid item md={6} xs={10} lg={3} >
                        {/*
                        <div style={{ marginLeft: "48%", fontSize: "23px", marginBottom: "-19px", flexDirection: 'row' }}>
                            <EyeFilled onClick={() => viewImage(_.get(loanInfo, _.get(v, 'proof')), _.get(v, 'proof'))} />
                            <DownCircleOutlined onClick={() => viewImage(_.get(loanInfo, _.get(v, 'proof')), _.get(v, 'proof'), 'down')} style={{ marginLeft: 23 }} />

                            <DeleteFilled onClick={() => deleteItem(v)} style={{ marginLeft: 23 }} />
                        </div>
*/}
                    </Grid>

                </Grid> : <Grid container item spacing={1}>
                    <Grid item md={6} xs={10} lg={3} >
                        <FormInputDropdown name="proof" control={control} label="Proof" options={profileSelectDetails} rules={{ required: CustomerRules.commonValidation("Proof", i + 1) }} readonly={isNextActive} />
                    </Grid>
                    <FormRow control={control} readonly={getValues('proof') ? false : true} name="proof_unique_no" label="Proof Unique No" rules={{ required: CustomerRules.commonValidation("Proof Unique No", i + 1) }} />
                    <Grid item md={6} xs={10} lg={3} >
                        <FormRowUpload name_title={getValues('proof')} value_detail={formList[i + 1]?.image_Details} type_dropdown="id_proof" name="proof_image" id={i + 1} disabled={getValues('proof_unique_no') ? false : true} label="Aadhaar" control={control} />
                    </Grid>
                    <Grid item md={6} xs={10} lg={2} >
                        {/* <PlusCircleFilled type="" className='add_proof_icon' size={40} style={{width:34,height:'45px',cursor:'pointer'}}  />  */}
                        <Icon baseClassName="fas" style={{
                            marginTop: 5, fontSize: 28, color: (isValid === false || setuploadvalue === false) && _.get(setImageValue, 'type_name') !== "proof_image" && _.get(setImageValue, 'id') !== i + 1 ? "#cccccc" :
                                _.get(setImageValue, 'type_name') === "proof_image" && _.get(setImageValue, 'id') === i + 1 ? "#4984BF" : "#cccccc"
                        }} className="fa-plus-circle" color="primary" onClick={() => setuploadvalue === true &&
                            _.get(setImageValue, 'type_name') === "proof_image" && _.get(setImageValue, 'id') === i + 1
                            && handleAdd()} />
                    </Grid>

                </Grid>
            })
        }

    }
    const loadFormView1 = (formList) => {
        console.log('sd,mf,msd,', getValues('proof_image'));
        if (_.size(formList) === 1) {

            return <Grid container item spacing={1}>
                <Grid item md={6} xs={10} lg={3} >
                    <FormInputDropdown name="proof_address" control={control} label="Proof" options={profileSelectDetails1} rules={{ required: CustomerRules.commonValidation("Proof") }} />
                </Grid>
                <FormRow control={control} readonly={getValues('proof_address') ? false : true} name="proof_unique_no_address" label="Proof Unique No" rules={{ required: CustomerRules.commonValidation("Proof Unique No") }} />
                <Grid item md={6} xs={10} lg={3} >
                    <FormRowUpload name_title={getValues('proof_address')} value_detail={formList[0]?.image_Details} type_dropdown="address_proof" name="proof_image_address" id="1" disabled={getValues('proof_unique_no_address') ? false : true} label="Aadhaar" control={control} />
                </Grid>
                <Grid item md={6} xs={10} lg={2} >
                    {/* <PlusCircleFilled type="" className='add_proof_icon' size={40} style={{width:34,height:'45px',cursor:'pointer'}}  />  */}
                    <Icon baseClassName="fas" style={{
                        marginTop: 5, fontSize: 28, color: (isValid === false || setuploadvalue === false) && _.get(setImageValue, 'type_name') !== "proof_image_address" && _.get(setImageValue, 'id') !== 1 ? "#cccccc" :
                            _.get(setImageValue, 'type_name') === "proof_image_address" && _.get(setImageValue, 'id') === 1 ? "#4984BF" : "#cccccc"
                    }} className="fa-plus-circle" color="primary" onClick={() => setuploadvalue === true &&
                        _.get(setImageValue, 'type_name') === "proof_image_address" && _.get(setImageValue, 'id') === 1 &&
                        handleAdd('', 'address')} />
                </Grid>

            </Grid>
        } else {
            return _.map(formList, (v, i) => {
                return _.get(v, 'upload') === true ? <Grid style={{ marginBottom: 34, marginTop: 10 }} key={i} container item spacing={1}>
                    <Grid item md={6} xs={10} lg={3} >
                        <div>{commanNameChange(_.get(v, 'proof_address'))}</div>
                    </Grid>
                    <Grid item md={6} xs={10} lg={3} >
                        <div>{_.get(v, 'proof_unique_no_address')}</div>
                    </Grid>
                    <Grid item md={6} xs={10} lg={3} >
                        <div>{_.get(v, 'proof_image_address.name')}</div>
                    </Grid>
                    {/*
                    <Grid item md={6} xs={10} lg={3} >
                        <div style={{ marginLeft: "48%", fontSize: "23px", marginBottom: "-19px", flexDirection: 'row' }}>
                            <EyeFilled onClick={() => viewImage(_.get(loanInfo, _.get(v, 'proof_address')), _.get(v, 'proof_address'))} />
                            <DownCircleOutlined onClick={() => viewImage(_.get(loanInfo, _.get(v, 'proof_address')), _.get(v, 'proof_address'), 'down')} style={{ marginLeft: 23 }} />

                            <DeleteFilled onClick={() => deleteItem(v, 'address')} style={{ marginLeft: 23 }} />
                        </div>

                    </Grid>
*/}
                </Grid> : <Grid container item spacing={1}>
                    <Grid item md={6} xs={10} lg={3} >
                        <FormInputDropdown name="proof_address" control={control} label="Proof" options={profileSelectDetails1} rules={{ required: CustomerRules.commonValidation("Proof", i + 1) }} />
                    </Grid>
                    <FormRow control={control} readonly={getValues('proof_address') ? false : true} name="proof_unique_no_address" label="Proof Unique No" rules={{ required: CustomerRules.commonValidation("Proof Unique No", i + 1) }} />
                    <Grid item md={6} xs={10} lg={3} >
                        <FormRowUpload name_title={getValues('proof_address')} id={i + 1} value_detail={formList[i + 1]?.image_Details} type_dropdown="address_proof" name="proof_image_address" disabled={getValues('proof_unique_no_address') ? false : true} label="Aadhaar" control={control} />
                    </Grid>
                    <Grid item md={6} xs={10} lg={2} >
                        {/* <PlusCircleFilled type="" className='add_proof_icon' size={40} style={{width:34,height:'45px',cursor:'pointer'}}  />  */}
                        <Icon baseClassName="fas" style={{
                            marginTop: 5, fontSize: 28, color: (isValid === false || setuploadvalue === false) &&
                                _.get(setImageValue, 'type_name') !== "proof_image_address" && _.get(setImageValue, 'id') !== i + 1 ? "#cccccc" :
                                _.get(setImageValue, 'type_name') === "proof_image_address" && _.get(setImageValue, 'id') === i + 1 ? "#4984BF" : "#cccccc"
                        }} className="fa-plus-circle" color="primary" onClick={() => setuploadvalue === true
                            && _.get(setImageValue, 'type_name') === "proof_image_address" && _.get(setImageValue, 'id') === i + 1
                            && handleAdd('', 'address')} />
                    </Grid>

                </Grid>
            })
        }

    }
    return (<form onSubmit={handleSubmit(onSubmit)} >
        <Card>
            <CardHeader
                title="Address / Identity Proofs"
            />

            <Divider />
            <CardContent>
                {
                    apiError.error && apiError.show === true && <div>
                        <SnackBar open={apiError.show} id={_.get(apiError, 'id')} message={apiError.message} handleClose={() => setApiError(initialErrorState)} severity={apiError.severity} />
                    </div>
                }
                <div >
                    <div className='proof_list'>
                        Id Proof
                    </div>
                    {loadFormView(formList)}
                </div>
                {/*  <div>
                    <div className='proof_list'>
                        Address Proof
                    </div>
                    {loadFormView1(addressProof)}
                </div> */}
            </CardContent>
            {/* <FormRowUpload name="pan_proof_url" id="2" label="Pan" control={control} />
      <FormRowUpload name="dirving_license_proof_url" id="3" label="Driving License" control={control} />
      <FormRowUpload name="passport_proof_url" label="Passport" id="4" control={control} />
      <FormRowUpload name="voters_id_proof_url" label="Voters ID" id="5" control={control} />
      <FormRowUpload name="family_card_proof_url" label="Family Card" id="6" control={control} />
      <FormRowUpload name="bank_book_proof_url" label="Bank Book" id="7" control={control} />
      <FormRowUpload name="others_proof_url" label="Others" id="8" control={control} /> */}
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
                <Button
                    variant="contained"
                    sx={{ mt: 2, ml: 1 }}
                    href="/app/customers"
                    style={{ height: '36px' }}
                >
                    Cancel
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}> {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                        Previous
                    </Button>
                )}

                    <Button
                        variant="contained"
                        sx={{ mt: 2, ml: 1 }}
                        type={"submit"}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
                    </Button>
                </Box>
            </Box>
        </Card>
    </form>
        // </Spin>
    );
};


export default ProofDetailsLoan;
