import React, { useState, useEffect } from 'react';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  IconButton,
  Button
} from '@mui/material';
import Icon from '@mui/material/Icon';
import DeleteIcon from '@mui/icons-material/Delete';

import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import _ from 'lodash';
import globalConfig from "../../utils/config";
const relationshipList = globalConfig.relationshipList;
const OccupationOptions = [
  {
    label: 'Central Government',
    value: 'central government'
  },
  {
    label: 'State Government',
    value: 'state government'
  },
  {
    label: 'Self Employee',
    value: 'self employee'
  },
  {
    label: 'Business',
    value: 'business'
  },
  {
    label: 'Others',
    value: 'others'
  }
]

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          id={props.id}
        />
      </Grid>
    </React.Fragment>
  );
}

const GuarantorDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo
}) => {
  let relationship = Object.entries(relationshipList).map((val, key) => { return { label: val[1], value: val[0] } });
  let details = customerInfo && customerInfo.guarantor_details && customerInfo.guarantor_details.map((x, index) => {
    return {
      name: x.name,
      age: x.age,
      relationship: x.relationship,
      gender: x.gender,
      address: x.address,
      occupation: x.occupation,
      contact_no: x.contact_no,

      // loan_amount: x.loan_amount,
      //deleteItem: x.deleteItem
    }
  });
  console.log('detailsdetailsdetails', details);
  let guarantorFamily = [{
    name: '',
    age: '',
    relationship: '',
    gender: '',
    address: '',
    occupation: '',
    contact_no: '',
    // loan_amount: '',
    //deleteItem: 0
  }]
  let x = _.size(details) > 0 ? details : guarantorFamily

  let defaultValues = {
    guarantor_details: x
    // name: customerInfo.name || '',
    // age: customerInfo.age || '',
    // relationship: customerInfo.relationship || '',
    // gender: '',
    // address: customerInfo.address || '',
    // occupation: '',
    // contact_no: customerInfo.contact_no || '',
  };

  let defaultValues1 = {
    references: x
  };
  const [guarantorDetails] = useState({ ...defaultValues, ...defaultValues1 });

  const { handleSubmit, control, reset, formState: { }, getFieldState, getValues } = useForm({
    defaultValues: { ...guarantorDetails }
  });
  console.log('defaultValuesdefaultValues', defaultValues);
  // useEffect(() => {
  //   setCustomerInfo({
  //     ...customerInfo, ...defaultValues
  //   })
  // }, [])

  const onSubmit = (data) => {
    console.log("guarantor", data);
    setCustomerInfo({
      ...customerInfo, guarantor_details: data.guarantor_details,
      references: data?.references
    })
    handleNext();
  }
  //const count = customerInfo && customerInfo.guarantor_details && customerInfo.guarantor_details.filter(item => item.deleteItem === 0) || 0;
  console.log('lastoneDatalastoneData',);
  // const lastoneData = (data) => {
  //   console.log('asdfmansmdas', data);
  // }


  const handleAdd = (e, type) => {
    if (type === "guarantor") {

      console.log('dslfksdnfsdfdf', getFieldState(`guarantor_details[0].name`), getValues());
      let a = _.get(customerInfo, 'guarantor_details[0].id') ? [...customerInfo.guarantor_details, ..._.get(getValues(), 'guarantor_details', []), guarantorFamily[0]] : [..._.get(getValues(), 'guarantor_details', []), guarantorFamily[0]]
      setCustomerInfo({
        ...customerInfo,
        guarantor_details: [...a
        ]
      })
      e.preventDefault();
    } else {
      let a = _.get(customerInfo, 'references[0].id') ? [...customerInfo.references, ..._.get(getValues(), 'references', []), guarantorFamily[0]] : [..._.get(getValues(), 'references', []), guarantorFamily[0]]
      setCustomerInfo({
        ...customerInfo,
        references: [...a
        ]
      })
      e.preventDefault();
    }
  }

  const handleRemove = (index, type) => {
    if (type === "guarantor") {
      let details = customerInfo.guarantor_details
      details.splice(index, 1);
      setCustomerInfo({
        ...customerInfo,
        guarantor_details: [...details]
      })
    } else {
      let details = customerInfo?.references
      details.splice(index, 1);
      setCustomerInfo({
        ...customerInfo,
        references: [...details]
      })
    }
  }

  React.useEffect(() => {
    reset(customerInfo)
  }, [customerInfo])
  console.log('customerInfocustomerInfocustomerInfosdfds', _.get(customerInfo, 'guarantor_details'));
  let customerInfoDetails = _.size(_.get(customerInfo, 'guarantor_details')) > 0 ? [...customerInfo.guarantor_details] : [{ id: 1 }]


  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Card>
        <CardHeader
          title="Reference Details"
        />
        <Divider />
        {_.size(customerInfoDetails) && customerInfoDetails.map((x, index) => {
          console.log(customerInfo.guarantor_details)
          // if (customerInfo.guarantor_details[index].deleteItem === 1) return ''
          // customerInfo.guarantor_details[index] && Object.keys(customerInfo.guarantor_details[index]).length > 0 &&
          // else {
          // return customerInfo.guarantor_details[index] && Object.keys(customerInfo.guarantor_details[index]).length > 0 &&
          return <>
            <CardContent key={index} id={"card" + index} className="family-card">
              <div className='comman_title_style'>Reference Details</div>
              <Grid container spacing={2} >
                <Grid container item spacing={2} >
                  <FormRow control={control} name={`guarantor_details[${index}].name`} label="Name" rules={CustomerRules.first_name} />
                  <FormRow control={control} name={`guarantor_details[${index}].age`} label="Age" inputType="number" rules={CustomerRules.age} />
                </Grid>
                <Grid container item spacing={2} >
                  <FormRow control={control} name={`guarantor_details[${index}].gender`} label="Gender" rules={CustomerRules.gender} />
                  <FormRow control={control} name={`guarantor_details[${index}].address`} label="Address" rules={CustomerRules.address1} />
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item md={6} xs={10} lg={4} >
                    <FormInputDropdown
                      name={`guarantor_details[${index}].relationship`}
                      control={control}
                      label="Relationship"
                      options={relationship}
                      rules={CustomerRules.guarantor_relationship}
                    />
                  </Grid>
                
                  <Grid item md={6} xs={10} lg={4} >
                  <FormInputDropdown control={control} name={`guarantor_details[${index}].occupation`} label="Occupation" options={OccupationOptions} rules={CustomerRules.occupation} />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} >
                  {/* <FormRow control={control} name={`guarantor_details[${index}].loan_company`} label="Loan Company" rules={CustomerRules.loan_company} /> */}
                  <FormRow control={control} inputType="number" name={`guarantor_details[${index}].contact_no`} label="Contact No" rules={CustomerRules.contact_no} />
                  {/* <FormRow control={control} name={`guarantor_details[${index}].loan_amount`} label="Loan Amount" inputType="number" rules={CustomerRules.loan_amount} /> */}
                </Grid>
              </Grid>
              {_.size(customerInfoDetails) > 1 &&
                <IconButton aria-label="delete" className="family-delete-button" onClick={() => handleRemove(index)}>
                  <DeleteIcon />
                </IconButton>}
            </CardContent>
          </>
          //}
        })}
        <div className="family-card-add-button-wrapper">
          <Icon baseClassName="fas" className="fa-plus-circle" color="primary" onClick={(e) => handleAdd(e, 'guarantor')} />
        </div>
        <div className="clear"></div>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: '36px' }}
          >
            Cancel
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>  {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
              Previous
            </Button>
          )}
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
            </Button>
          </Box></Box>
      </Card>
    </form>
  );
};


export default GuarantorDetails;
