import * as React from 'react';
import {
  Box,
  Typography,
  Divider,
  Grid,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { services } from 'src/components/services/services';
import { useNavigate } from "react-router-dom";
import _ from 'lodash'
import { notification } from 'antd';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import Paper from '@mui/material/Paper';
import './style.css'
// const Demo = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   borderRadius: '5px'
// }));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export default function Review({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  id
}) {
  let navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('panel1');
  console.log('customerInfo', customerInfo);
  const handleChange = (panel) => (event, newExpanded) => {
    console.log('dlamflndsmfnmsd', panel, event, newExpanded);
    setExpanded(newExpanded ? panel : false);
  };

  const convertDetails = () => {
    let c = _.pick(customerInfo, ['first_name', 'last_name', 'email', 'aadhar_id', 'mobile_number', 'dob', 'gender',
      'spouse_name', 'fathers_name', 'mothers_name', 'education', 'maritial_status', 'religion', 'caste', 'occupation', 'residence_type', 'physically_challenged',


    ])
    c.incomeAndExpenses = _.pick(customerInfo, ['house_rent', 'eb_charges', 'education_expenses', 'food_dresses_maintenance_expenses', 'medical_expenses', 'agriculture_expenses', 'business_expenses', 'total_household_expense', 'loan_installments', 'total_household_income', 'total_household_income_net'])
    c['bankDetails'] = _.pick(customerInfo, ["nameOfAccount", "account_number", "branch_name", "bank_name", "ifsc_code"])
    c['family_details'] = customerInfo?.family_details
    c['guarantor_details'] = customerInfo?.guarantor_details
    c['references'] = customerInfo?.references
    c['addressDetails'] = {
      permenant: {
        "number": _.get(customerInfo, 'permenant_number'),
        "address1": _.get(customerInfo, 'permenant_address1'),
        "address2": _.get(customerInfo, 'permenant_address2'),
        "city_village_town": _.get(customerInfo, 'permenant_city_village'),
        "district": _.get(customerInfo, 'permenant_district'),
        "pin_code": _.get(customerInfo, 'permenant_state'),
        "landmark": _.get(customerInfo, 'permenant_landmark'),
        "state": _.get(customerInfo, 'permenant_pin_code'),
      },
      "present": {
        "same_as_permenant": true,
        "number": _.get(customerInfo, 'present_number'),
        "address1": _.get(customerInfo, 'present_address1'),
        "address2": _.get(customerInfo, 'present_address2'),
        "city_village_town": _.get(customerInfo, 'present_city_village'),
        "district": _.get(customerInfo, 'present_district'),
        "pin_code": _.get(customerInfo, 'present_state'),
        "landmark": _.get(customerInfo, 'present_landmark'),
        "state": _.get(customerInfo, 'present_pin_code'),
      }
    }

    c.photo = 's3-url'
    c.dob = "18-08-1977"


    // _.pick(customerInfo,["name","age","relationship","income","gender","address","occupation","contact_no"])

    const loadAddressProof = (type) => {
      let bac = []
      if (type === 'address') {
        _.map(customerInfo?.addres_proof, (v, i) => {
          // if (_.get(v, 'upload') === true) {
          // bac.push({
          //   type: _.get(v, 'proof_address'),
          //   uniqueno: _.get(v, 'proof_unique_no_address'),
          //   "url": _.get(v, 'url'),
          // })
          // }

          bac.push({
            type: 'proof_address',
            uniqueno: 'proof_unique_no_address',
            "url": 'url'
          })
        })
      } else {
        _.map(customerInfo?.id_proof, (v, i) => {
          // if (_.get(v, 'upload') === true) {
          //   bac.push({
          //     type: _.get(v, 'proof'),
          //     uniqueno: _.get(v, 'proof_unique_no'),
          //     "url": _.get(v, 'url'),
          //   })
          // }

          bac.push({
            type: 'proof',
            uniqueno: 'proof_unique_no',
            "url": 'url'
          })
        })
      }



      return bac
    }

    // let b = {
    //   ...c,
    //   "documents": {
    //     "addressProof": loadAddressProof('address'),
    //     "idProof": loadAddressProof(''),
    //   },


    // }

    let b = {
      ...c,
      // "documents":{
      //    "addressProof":loadAddressProof('address'),
      //    "idProof": loadAddressProof(''),
      // },
      "documents": {
        "addressProof": [
          {
            "type": "aadhar",
            "uniqueno": "100000",
            "url": "iiiiii"
          }
        ],
        "idProof": [
          {
            "type": "pan",
            "uniqueno": "100000",
            "url": "iiiiii"
          }
        ]
      },
    }


    return b
  }

  const addCustomer = () => {
    let converDetails = convertDetails()

    let details = { ...converDetails, physically_challenged: _.get(converDetails, 'physically_challenged') === 'yes' ? "yes" : "no" }
    id > 0 ? services.putService(`customer/edit/${id}`, details)
      .then((res) => {

        console.log('res_asfafa', res);
        navigate(`/app/customers/details/${id}`, {
          state: {
            status: res.status,
            message: res.message,
          }
        })

      })
      .catch(err => {
        if (_.get(err, 'data.results.aadhar_id[0]')) {
          notification.error({
            message: "Error",
            description: _.get(err, 'data.results.aadhar_id[0]'),
            placement: 'bottomRight'
          })
        } else if (_.get(err, 'data.results') && _.size(_.get(err, 'data.results')) > 0) {
          notification.error({
            message: "Error",
            description: _.get(err, `data.results.${Object.keys(_.get(err, 'data.results'))[0]}[0]`),
            placement: 'bottomRight'
          })
        }
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      }) :
      services.postService(`customer/add`, details)
        .then((res) => {
          console.log('res', res);

          navigate(`/app/customers`, {
            state: {
              status: res.status,
              message: res.message,
            }
          })

        })
        .catch(err => {
          // if (_.get(err, 'data.results.aadhar_id[0]')) {
          //   notification.error({
          //     message: "Error",
          //     description: _.get(err, 'data.results.aadhar_id[0]'),
          //     placement: 'bottomRight'
          //   })
          // } else if (_.get(err, 'data.results') && _.size(_.get(err, 'data.results')) > 0) {
          //   notification.error({
          //     message: "Error",
          //     description: _.get(err, `data.results.${Object.keys(_.get(err, 'data.results'))[0]}[0]`),
          //     placement: 'bottomRight'
          //   })
          // }
          console.log("err1", err.data.message);
          notification.error({
            message: 'Error',
            description: err.data.message,
            placement: 'topRight'
          });
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
        })

  }

  const accountInfodetails = (type_name) => {
    let a = []
    if (type_name === "account") {
      a = _.pick(customerInfo, ['first_name', 'last_name', 'email', 'aadhar_id', 'mobile_number', 'dob', 'gender'])

    } else if (type_name === "personal") {
      a = _.pick(customerInfo, ['spouse_name', 'fathers_name', 'mothers_name', 'education', 'maritial_status', 'religion', 'caste', 'occupation', 'residence_type', 'physically_challenged'])
    } else if (type_name === "permenant_address") {
      a = _.pick(customerInfo, ['permenant_number', 'permenant_address1', 'permenant_address2', 'permenant_city_village', 'permenant_district', 'permenant_state', 'permenant_landmark', 'permenant_pin_code'])
    } else if (type_name === "present_address") {
      a = _.pick(customerInfo, ['present_number', 'present_address1', 'present_address2', 'present_city_village', 'present_district', 'present_state', 'present_landmark', 'present_pin_code'])
    } else if (type_name === "income") {
      a = _.pick(customerInfo, ['house_rent', 'eb_charges', 'education_expenses', 'food_dresses_maintenance_expenses', 'medical_expenses', 'agriculture_expenses', 'business_expenses', 'total_household_expense', 'loan_installments', 'total_household_income', 'total_household_income_net'])

    } else if (type_name === "guarantor") {
      a = _.size(customerInfo.guarantor_details) > 0 && _.isArray(customerInfo.guarantor_details) ? customerInfo.guarantor_details : []
    } else {
      a = _.size(customerInfo?.references) > 0 && _.isArray(customerInfo?.references) ? customerInfo?.references : []
    }






    return type_name === 'family' || type_name === 'guarantor' ? _.map(a, (v, i) => {
      return <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} key={i}>
        {_.map(v, (k, value) => {
          return <Grid item xs={2} sm={4} md={4} key={k}>
            <div>
              <div className='comman_title_style'>{_.startCase(value)}:

              </div>
              <div className='comman_value_style'>
                {k}
              </div>
            </div>
          </Grid>
        })}
      </Grid>
    }) : <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {_.map(a, (k, value) => {
        return <Grid item xs={2} sm={4} md={4} key={k}>
          <div>
            <div className='comman_title_style'>{_.startCase(value)}:

            </div>
            <div className='comman_value_style'>
              {k}
            </div>
          </div>
        </Grid>
      })}
    </Grid>
  }

  return (
    <React.Fragment>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography> Account Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('account')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>  Personal Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('personal')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>   Permenant Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('permenant_address')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>   Present Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('present_address')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>  Income Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('income')}
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>  Reference Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('guarantor')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography> Family Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accountInfodetails('family')}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ flexGrow: 1 }} style={{ padding: '20px', borderRadius: '10px' }}>


        {/* <Grid container spacing={2}>
       
        <Grid item xs={12} md={4}>
          <Typography sx={{ mt: 4, mb: 1 }} variant="h6" component="div">
            Family Details
          </Typography>
          <Demo>
            {
              customerInfo && customerInfo.guarantor_details != undefined && customerInfo.guarantor_details.map(family => {
                return (
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary={`Name : ${family.name}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Age : ${family.age}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Age : ${family.gender}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Relationship : ${family.relationship}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Relationship : ${family.contact_no}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Income : ${family.address}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Loan company : ${family.occupation}`}></ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Loan Amount : ${family.income}`}></ListItemText>
                    </ListItem>
                  </List>
                )
              })
            }
          </Demo>
        </Grid>
      </Grid> */}
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: '36px' }}
          >
            Cancel
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>  {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
              Previous
            </Button>
          )}
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={addCustomer}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
            </Button>
          </Box></Box>
      </Box>
    </React.Fragment >
  );
}