import React, { useState } from 'react';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  IconButton,
  Button
} from '@mui/material';
import Icon from '@mui/material/Icon';
import DeleteIcon from '@mui/icons-material/Delete';

import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import _ from 'lodash';
import globalConfig from "../../utils/config";
// import ReferenceDetails from './referenceDetails';
const relationshipList = globalConfig.relationshipList;
const OccupationOptions = [
  {
    label: 'Central Government',
    value: 'central government'
  },
  {
    label: 'State Government',
    value: 'state government'
  },
  {
    label: 'Self Employee',
    value: 'self employee'
  },
  {
    label: 'Business',
    value: 'business'
  },
  {
    label: 'Others',
    value: 'others'
  }
]

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          id={props.id}
        />
      </Grid>
    </React.Fragment>
  );
}

const FamilyDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
  let relationship = Object.entries(relationshipList).map((val, key) => { return { label: val[1], value: val[0] } });
  let details = customerInfo && customerInfo.family_details && customerInfo.family_details.map((x, index) => {
    return {
      name: x.name,
      age: x.age,
      relationship: x.relationship,
      income: x.income,
      loan_company: x.loan_company,
      gender: x.gender,
      address: x.address,
      occupation: x.occupation,
      contact_no: x.contact_no,

      // loan_amount: x.loan_amount,
      //deleteItem: x.deleteItem
    }
  });
  console.log('detailsdetailsdetails', details);
  let emptyFamily = [{
    name: '',
    age: '',
    relationship: '',
    income: '',
    loan_company: '',
    gender: '',
    address: '',
    occupation: '',
    contact_no: '',
    // loan_amount: '',
    //deleteItem: 0
  }]
  let x = _.size(details) > 0 ? details : emptyFamily

  let defaultValues = {
    family_details: x
  };
  let defaultValues1 = {
    references: x
  };
  const [familyDetails] = useState({ ...defaultValues, ...defaultValues1 });

  // const [referenceDetails,setReferenceDetails] = useState(defaultValues);
  // const [submitForm, setsubmitForm] = useState('false-232323')
  console.log('defaultValues', 'defaultValues', defaultValues);
  const { handleSubmit, control, reset, formState: { }, getFieldState, getValues } = useForm({
    defaultValues: { ...familyDetails }
  });
  console.log('defaultValuesdefaultValues', defaultValues);
  // useEffect(() => {

  //   setCustomerInfo({
  //     ...customerInfo, ...defaultValues
  //   })
  //   let x = _.size(details)>0  ? details : emptyFamily
  //   setFamilyDetails(x)
  //   console.log('sdfdfsddefaultValues',defaultValues);
  // }, [])

  const onSubmit = (data) => {
    console.log('hjffdgdsfgdfgfdf575e5e5', data);
    // setsubmitForm(`true-${Math.random()}`)
    setCustomerInfo({
      ...customerInfo, family_details: data.family_details,
      references: data?.references
    })
    handleNext();
  }
  //const count = customerInfo && customerInfo.family_details && customerInfo.family_details.filter(item => item.deleteItem ==== 0) || 0;
  console.log('lastoneDatalastoneData',);
  // const lastoneData=(data)=>{
  //   console.log('asdfmansmdas',data);
  // }
  const handleAdd = (e, type) => {
    if (type === "family") {
      console.log('dslfksdnfsdfdf', getFieldState(`family_details[0].name`), getValues());
      let a = _.get(customerInfo, 'family_details[0].id') ? [...customerInfo.family_details, ..._.get(getValues(), 'family_details', []), emptyFamily[0]] : [..._.get(getValues(), 'family_details', []), emptyFamily[0]]
      setCustomerInfo({
        ...customerInfo,
        family_details: [...a
        ]
      })
      e.preventDefault();
    } else {
      let a = _.get(customerInfo, 'references[0].id') ? [...customerInfo.references, ..._.get(getValues(), 'references', []), emptyFamily[0]] : [..._.get(getValues(), 'references', []), emptyFamily[0]]
      setCustomerInfo({
        ...customerInfo,
        references: [...a
        ]
      })
      e.preventDefault();
    }



  }

  const handleRemove = (index, type) => {
    if (type === "family") {
      let details = customerInfo.family_details
      details.splice(index, 1);
      setCustomerInfo({
        ...customerInfo,
        family_details: [...details]
      })
    } else {
      let details = customerInfo?.references
      details.splice(index, 1);
      setCustomerInfo({
        ...customerInfo,
        references: [...details]
      })
    }

  }

  React.useEffect(() => {
    reset(customerInfo)
  }, [customerInfo])
  console.log('customerInfocustomerInfocustomerInfosdfds', _.get(customerInfo, 'family_details'));
  let customerInfoDetails = _.size(_.get(customerInfo, 'family_details')) > 0 ? [...customerInfo.family_details] : [{ id: 1 }]
  let customerInfoDetailsReference = _.size(_.get(customerInfo, 'references')) > 0 ? [...customerInfo?.references] : [{ id: 1 }]

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Card>
        <CardHeader
          title="Family Details"
        />
        <Divider />
        {_.size(customerInfoDetails) && customerInfoDetails.map((x, index) => {
          console.log(customerInfo.family_details)
          // if (customerInfo.family_details[index].deleteItem ==== 1) return ''
          // customerInfo.family_details[index] && Object.keys(customerInfo.family_details[index]).length > 0 &&
          // else {
          return <>
            <CardContent key={index} id={"card" + index} className="family-card">
              <div className='comman_title_style'>Family details</div>
              <Grid container spacing={2} >
                <Grid container item spacing={2} >
                  <FormRow control={control} name={`family_details[${index}].name`} label="Name" rules={CustomerRules.first_name} readonly={isNextActive} />
                  <FormRow control={control} name={`family_details[${index}].age`} label="Age" inputType="number" rules={CustomerRules.age} readonly={isNextActive} />
                </Grid>
                <Grid container item spacing={2} >
                  <FormRow control={control} name={`family_details[${index}].gender`} label="Gender" rules={CustomerRules.gender} readonly={isNextActive} />
                  <FormRow control={control} name={`family_details[${index}].address`} label="Address" rules={CustomerRules.address1} readonly={isNextActive} />
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item md={6} xs={10} lg={4} >
                    <FormInputDropdown
                      name={`family_details[${index}].relationship`}
                      control={control}
                      label="Relationship"
                      options={relationship}
                      rules={CustomerRules.guarantor_relationship}
                      readonly={isNextActive}
                    />
                  </Grid>
                  <Grid item md={6} xs={10} lg={4} >
                  <FormInputDropdown control={control} name={`family_details[${index}].occupation`} label="Occupation" options={OccupationOptions} rules={CustomerRules.occupation} readonly={isNextActive} /> 
                  </Grid>

                </Grid>
                <Grid container item spacing={2} >
                  {/* <FormRow control={control} name={`family_details[${index}].loan_company`} label="Loan Company" rules={CustomerRules.loan_company} /> */}
                  <FormRow control={control} name={`family_details[${index}].income`} label="Income" rules={CustomerRules.net_income} readonly={isNextActive} />
                  <FormRow control={control} inputType="number" name={`family_details[${index}].contact_no`} label="Contact No" rules={CustomerRules.contact_no} readonly={isNextActive} />

                  {/* <FormRow control={control} inputType="number" name="mobile_number" label="Mobile number" rules={CustomerRules.mobile_number} />*/}
                  {/* <FormRow control={control} name={`family_details[${index}].loan_amount`} label="Loan Amount" inputType="number" rules={CustomerRules.loan_amount} /> */}
                </Grid>
                {/* <Grid container item spacing={2} >
                  <FormRow control={control} name={`family_details[${index}].loan_company`} label="Loan Company" rules={CustomerRules.loan_company} readonly={isNextActive} /> */}
                  {/* <FormRow control={control} name={`family_details[${index}].loan_amount`} label="Loan Amount" inputType="number" rules={CustomerRules.loan_amount} /> */}
                {/* </Grid> */}
              </Grid>
              {_.size(customerInfoDetails) > 1 &&
                <IconButton aria-label="delete" className="family-delete-button" onClick={() => handleRemove(index, 'family')}>
                  <DeleteIcon />
                </IconButton>}
            </CardContent>
          </>
          //}
        })}
         <div className="family-card-add-button-wrapper">
          <Icon baseClassName="fas" className="fa-plus-circle" color="primary" onClick={(e) => handleAdd(e, 'family')} />
        </div> 
        {_.size(customerInfoDetailsReference) && customerInfoDetailsReference.map((x, index) => {


          return <>
            {/*
          */}
            {/* <CardContent key={index} id={"card" + index} className="family-card">
              <div className='comman_title_style'>Family details</div>
              <Grid container spacing={2} >
                <Grid container item spacing={2} >
                  <FormRow control={control} name={`references[${index}].name`} label="Name" rules={CustomerRules.first_name} readonly={isNextActive} />
                  <FormRow control={control} name={`references[${index}].age`} label="Age" inputType="number" rules={CustomerRules.age} readonly={isNextActive} />
                </Grid>
                <Grid container item spacing={2} >
                  <FormRow control={control} name={`references[${index}].gender`} label="Gender" rules={CustomerRules.gender} readonly={isNextActive} />
                  <FormRow control={control} name={`references[${index}].address`} label="Address" rules={CustomerRules.address1} readonly={isNextActive} />
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item md={6} xs={10} lg={4} >
                    <FormInputDropdown
                      name={`references[${index}].relationship`}
                      control={control}
                      label="Relationship"
                      options={relationship}
                      rules={CustomerRules.guarantor_relationship}
                      readonly={isNextActive}
                    />
                  </Grid>
                  <FormRow control={control} name={`references[${index}].occupation`} label="Occupation" rules={CustomerRules.occupation} readonly={isNextActive} />

                </Grid>
                <Grid container item spacing={2} >
                  <FormRow control={control} inputType="number" name={`references[${index}].contact_no`} label="Contact No" rules={CustomerRules.contact_no} readonly={isNextActive} />
                </Grid>
              </Grid>
              {_.size(customerInfoDetailsReference) > 1 &&
                <IconButton aria-label="delete" className="family-delete-button" onClick={() => handleRemove(index)}>
                  <DeleteIcon />
                </IconButton>}
            </CardContent> */}
          </>
          //}
        })}
        {/* <div className="family-card-add-button-wrapper">
          <Icon baseClassName="fas" className="fa-plus-circle" color="primary" onClick={(e) => handleAdd(e, '')} />
        </div>  */}


        <div className="clear"></div>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: '36px' }}
          >
            Cancel
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>  {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
              Previous
            </Button>
          )}
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
            </Button>
          </Box></Box>
      </Card>
    </form>
  );
};


export default FamilyDetails;
