import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  TableContainer
} from '@mui/material';
import getInitials from '../../utils/getInitials';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import DialogBox from '../shared/DialogBox';
import { services } from '../services/services';
import SnackBar from '../shared/SnackBar';
import _ from 'lodash'
import { Spin } from 'antd';

const UserListResults = ({ users,onChangePage,pagination_details,loading, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [page1, setPage1] = useState(0);

  // const initialErrorState = {
  //   severity: '',
  //   message: '',
  //   error: false
  // }
  // let [apiError, setApiError] = useState(initialErrorState);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
console.log('event.target.valueevent.target.value',event.target.value);
    onChangePage(page1,event.target.value )
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setPage1(page<newPage? newPage+10:newPage-10)
    onChangePage(page<newPage? newPage+10:newPage-10,limit)
  };

  const onConfirm = () => {
    setOpen(false);
    services.putService(`user/delete/${userId}`)
      .then(res => {
        console.log(res);
        setOpenAlert(true);
      })
      .catch(err => err);
  }

  const handleDeleteUser = (user_id) => {
    setOpen(true);
    setUserId(user_id);
  }

  const handleClose = () => {
    setOpenAlert(false);
  }

  return (
    <>
      <SnackBar open={openAlert} message="User Deleted" handleClose={handleClose} severity="success"/>
      <Card {...rest}>
          <Box >
          <TableContainer style={{ maxHeight: "550px",overflowX:'auto' }}>
          <Table stickyHeader >
              <TableHead>
                <TableRow>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    Username
                  </TableCell>
                  <TableCell>
                    Role
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, limit).map((user) => (
                  <TableRow
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={user.avatarUrl}
                          sx={{ mr: 2 }}
                        >
                          {getInitials(user.name)}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {user.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {user.email}
                    </TableCell>
                    <TableCell>
                      {user.username}
                    </TableCell>
                    <TableCell>
                      {user.role}
                    </TableCell>
                    <TableCell>
                      <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <IconButton>
                          <DeleteIcon onClick={()=> {setUserName(user.username); handleDeleteUser(user._id)} }/>
                        </IconButton>
                        <IconButton component={Link} to={`${user._id}`}>
                          <EditIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                 {loading===false &&users.length ===0 && <TableRow style={{height:'250px'}} > <TableCell  colSpan={9} style={{alignItems:'center',textAlign:'center',marginTop:'50px',marginBottom:"50px"}}>No Records Found</TableCell> </TableRow> }
              
              {loading===true && <TableRow style={{height:'250px'}} > <TableCell  colSpan={9} style={{alignItems:'center',textAlign:'center',marginTop:'50px',marginBottom:"50px"}}> <Spin size="large" /></TableCell> </TableRow>}
              </TableBody>
            </Table></TableContainer>
          </Box>
        <TablePagination
          component="div"
          count={_.get(pagination_details,'total',10)}        onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={_.get(pagination_details,'count',10)}
          rowsPerPageOptions={[5, 10, 25]}
        />
        <DialogBox open={open} user_name={userName} setOpen={setOpen} onConfirm={onConfirm} />
      </Card>
    </>
  );
};

UserListResults.propTypes = {
  Users: PropTypes.array.isRequired
};

export default UserListResults;
