import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';

/*
Sample for calling FormInputDropdown
<FormInputDropdown
  name="test"
  control={control}
  label="test"
  options={options}
  rules={rules.test}
/>
https://github.com/Mohammad-Faisal/react-hook-form-material-ui/blob/master/src/form-components/FormInputMultiCheckbox.tsx
https://blog.logrocket.com/using-material-ui-with-react-hook-form/
*/

export const FormInputDropdown = ({
  name,
  control,
  label,
  options,
  rules,
  readonly
}) => {
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      render={(
        {
          field: { onChange, value },
          fieldState: { error },
        }) => (
        <FormControl size={"small"} fullWidth error={error} disabled={readonly}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            onChange={onChange}
            value={value}
            disabled={readonly}
          >
            {generateSingleOptions()}
          </Select>
          <FormHelperText sx={{ ml: 2 }} error={error}>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
      rules={{
        ...fieldRules
      }}
      control={control}
      name={name}
    />
  );
};