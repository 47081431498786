import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { services } from 'src/components/services/services';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import SnackBar from 'src/components/shared/SnackBar';
import EmiListResults from 'src/components/emi/emiTable';
// import _ from 'lodash'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Card, CardContent, Grid, Divider, CardHeader, TableContainer, TableRow, Table, TableBody } from '@mui/material';
import { styled } from '@mui/material/styles';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';

const EMIList = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(97, 97, 97)',
    transition: 'none',
    backgroundImage: 'none',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid rgb(227, 242, 253)',
    // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: "none",
    '&:hover': {
      // border: '2px solid rgb(227, 242, 253)',
      // boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px'
    }
  }))
  let { id } = useParams();

  const location = useLocation();
  const [emis, setEmis] = useState([]);
  // const [searchedInputs, setSearchedInputs] = useState('');
  // const [searchedAadhar, setSearchedAadhar] = useState('');
  // const [searchedCustomerName, setSearchedCustomerName] = useState('');
  // const [searchedCustomerStatus, setSearchedStatus] = useState('');
  const [open, setOpen] = useState(true);
  // const loanDelayedQuery = _.debounce(q => handleSearch(q), 1200);
  // const [pageDetails, setPageDetails] = useState({});
  const [details, setDetails] = useState({})
  const [searchParams] = useSearchParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();

  {/* pending_review|approved|rejected|sent_back|disbursed|closed|overdue */ }
  //  const status ={Pending:'pending_review',Approved:'approved',Disbursed:'disbursed',Rejected:'rejected','Sent back':'sent_back','Closed':'closed'}

  // const handleChange = (e) => {
  //   console.log('ee',e);
  //   setSearchedInputs({
  //     ...searchedInputs, [e.target.name]: e.target.name=='status'? _.get(status,e.target.value):e.target.value
  //   });
  //   loanDelayedQuery({
  //     ...searchedInputs, [e.target.name]:e.target.name=='status'? _.get(status,e.target.value):e.target.value
  //   })
  // }

  const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    backgroundColor: '#038dd7',
    color: '#5D35B1'
  }))

  // const TabelHeaderCell = styled(TableCell)(({ theme }) => ({
  //   backgroundColor: '#EDE7F6',
  //   color: '#5D35B1'
  // }))

  const MuiTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none"
    }
  }));

  const BoldTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      fontWeight: '500'
    }
  }));

  //   const handleSearch = (q) => {
  //     let userDetails =jwtDecode(localStorage.getItem('token'))
  //       let query_details ={...q,addedBy:_.get(userDetails,'user.user_id')}
  //     let queryValues = new URLSearchParams(query_details).toString();
  //     console.log('queryValues',queryValues);
  //     services.getService(`loan/list?${queryValues}`)
  //       .then(res => setLoans(res.data))
  //       .catch(err => {
  // if(!localStorage.getItem('token')){
  //  navigate('/login');
  // }
  // })
  //   }
  const loanList = () => {
    services.getService(`loan/detail/${id}`)
      .then(res => {
        let data = res.data.filter(l => l.id === id);
        console.log('sdfsfsd', data);
        setDetails({
          ..._.get(res, 'data')
        })

        console.log("res", res);
        console.log("data", data);
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }
  const getLoanList = (start = 0) => {
    console.log('tokes', jwtDecode(localStorage.getItem('token')));
    // let userDetails =jwtDecode(localStorage.getItem('token'))
    services.getService(`loan/emi-chart/${id}`)
      .then(res => {
        console.log('Loan chart', res);
        setEmis(res.data)
        console.log("emis", emis);
        // setPageDetails({count:_.get(res,'count'),start:_.get(res,'start')})

      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const changePage = (value) => {
    getLoanList(value)
  }

  useEffect(() => {
    loanList()
    getLoanList()
  }, [])


  const handleClose = () => {
    setOpen(false);
  };
  const breadCrumbsData = [{ name: searchParams.get('type') === "my_loan" ? 'My Loans' : 'Loans', path: searchParams.get('type') === "my_loan" ? '/app/my-loans' : '/app/loans' }, { name: 'EMI', path: `/app/emi/${id}` }]

  return (
    <>
      <Helmet>
        <title>EMI - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          location && location.state && location.state.status && <SnackBar open={open} message={location.state.message} handleClose={handleClose} severity="success" />
        }
        <Container maxWidth={false} >
          <BreadcrumbsComponent data={breadCrumbsData} />
          <Button onClick={() => handlePrint()}>Print</Button>
          <div ref={componentRef}>
            <div className="emi-details-contain" >
              <Grid style={{ marginTop: 23 }} container spacing={2}>

                <Grid item md={12} lg={12}>
                  <StyledCard>
                    <StyledCardHeader title="EMI Details" />
                    <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                    <CardContent>
                      <TableContainer style={{ overflowX: 'auto' }}>
                        <Table sx={{ minWidth: 580 }} size="small" aria-label="simple table">
                          <TableBody>
                            <TableRow hover>
                              <BoldTableCell>Name</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'name')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Email</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'email')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Customer Id</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'customer_id')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Aadhar Id</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'aadhar_id')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Disbursed On</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'disbursed_date')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Expected Date</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'expected_date')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Interest Rate</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'interest_rate')}</MuiTableCell>
                            </TableRow>
                            <TableRow hover>
                              <BoldTableCell>Tenure</BoldTableCell>
                              <MuiTableCell>:</MuiTableCell>
                              <MuiTableCell>{_.get(details, 'tenure')}</MuiTableCell>
                            </TableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </StyledCard>
                </Grid>

              </Grid>


            </div>
            <Box sx={{ pt: 3 }}>
              <EmiListResults onChangePage={(value) => changePage(value)}
                // pagination_details={pageDetails}
                emis={emis} />
            </Box></div>
        </Container>
      </Box>
    </>
  )
}
export default EMIList